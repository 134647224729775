<template>
  <div class="app-content app-container">
    <!-- 搜索 -->
    <div style="margin-bottom: 20px">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
        @submit.native.prevent
      >
        <el-form-item label="企业标签" prop="type">
          <el-select v-model="formInline.type" clearable placeholder="请选择">
            <el-option
              v-for="item in companyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信用代码" prop="uscc">
          <el-input
            style="width: 300px"
            v-model="formInline.uscc"
            placeholder="请输入"
            clearable
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            style="width: 300px"
            v-model="formInline.name"
            placeholder="请输入"
            clearable
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" plain @click="addReport">新增</el-button>
        <el-button type="danger" plain @click="allDelteClick">删除</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="#" width="60"></el-table-column>
        <el-table-column prop="uscc" label="信用代码"> </el-table-column>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="customsCode" label="海关代码"> </el-table-column>
        <el-table-column prop="provinceName" label="所在地">
          <template slot-scope="scope">
            {{ scope.row.provinceName }},{{ scope.row.cityName }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="企业标签">
          <template slot-scope="scope">
            <div v-if="scope.row.type">
              <el-tag :type="scope.row.type | companyTypeTag" effect="plain">
                {{ scope.row.type | companyType }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="numberWJ" label="参与问卷数"> </el-table-column>
        <el-table-column prop="activityNumber" label="企业活跃度">
          <template slot-scope="scope">
            <div>
              {{ scope.row.activityNumber > 2 ? "活跃" : "不活跃" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="editClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="previewClick(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div>
        <Pagination
          v-bind:child-msg="pageparm"
          @callFather="callFather"
        ></Pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getCompanyChainListApi,
  getCompanyChainDeleteApi,
} from "@/api/company";
export default {
  // 注册组件
  components: {
    Pagination,
  },
  data() {
    return {
      companyType: this.enterpriseLabel,
      formInline: {
        name: "",
      },
      tableData: [],
      multipleSelection: [],
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 20,
      },
    };
  },
  created() {
    if (sessionStorage.getItem("formInline")) {
      this.formInline = JSON.parse(sessionStorage.getItem("formInline"));
    }
    this.getCompanyListFun();
  },
  methods: {
    async getCompanyListFun(currentPage, pageSize) {
      var start = currentPage ? currentPage * pageSize - pageSize : 0;
      var params = {
        start: start,
        length: 10,
        draw: 1,
        orderStr: "time desc",
      };
      var p = Object.assign(params, this.formInline);
      var res = await getCompanyChainListApi(p);
      console.log("企业列表", res);
      this.tableData = res.data;
      this.pageparm.total = res.recordsTotal;
    },
    // 删除
    async allDelteClick() {
      var ids = this.multipleSelection.map((item) => item.id);
      this.$alert(
        "这确定要删除共" +
          this.multipleSelection.length +
          "条数据吗？删除后将无法恢复",
        "删除提示",
        {
          confirmButtonText: "确定",
          callback: async (action) => {
            if (action === "confirm") {
              var params = {
                ids: ids,
                menuType: "delete",
              };
              var res = await getCompanyChainDeleteApi(params);
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getCompanyListFun();
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败：" + res.message,
                });
              }
            }
          },
        }
      );
    },
    onSubmit() {
      sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      this.getCompanyListFun();
    },
    resetForm(formInline) {
      sessionStorage.removeItem("formInline");
      this.formInline = {
        name: "",
      };
      this.$refs[formInline].resetFields();
      this.getCompanyListFun();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页插件事件
    callFather(parm) {
      this.getCompanyListFun(parm.currentPage, parm.pageSize);
    },
    // 新增模板
    addReport() {
      this.$router.push({
        path: "/industrialChainEnterprise/add",
        query: {
          type: 2,
        },
      });
    },
    // 编辑模板
    editClick(row) {
      console.log("row", row);
      this.$router.push({
        path: "/industrialChainEnterprise/add",
        query: {
          id: row.uscc,
          type: 2,
        },
      });
    },
    // 预览
    previewClick(row) {
      this.$router.push({
        path: "/industrialChainEnterprise/preview",
        query: {
          type: 2,
          uscc: row.uscc,
        },
      });
    },
  },
};
</script>
<style scoped>
.app-content {
  background-color: #fff;
  padding: 20px;
}
</style>
